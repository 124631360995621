@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "pblack";
  src: url("./css/Pangram-Black.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "pbold";
  src: url("./css/Pangram-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "pextrabold";
  src: url("./css/Pangram-ExtraBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "pextraligth";
  src: url("./css/Pangram-ExtraLight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "pligth";
  src: url("./css/Pangram-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "pmedium";
  src: url("./css/Pangram-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "pregular";
  src: url("./css/Pangram-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.fondo1 {
  background-image: url("./img/fondo1.png");
  height: 200px; /* Altura para móviles */
  background-size: cover; /* Asegúrate de que la imagen cubra el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

/* Estilos para pantallas más grandes (PC) */
@media (min-width: 640px) {
  /* Cambia 640px por el tamaño que desees */
  .fondo1 {
    background-image: url("./img/fondo1.png"); /* Cambia la imagen para PC */
    height: 300px; /* Altura para pantallas más grandes */
  }
}

.fondo2 {
  background-image: url("./img/fondo2.png");
  height: 300px;
  width: 100%;
  background-size: cover; /* Asegúrate de que la imagen cubra el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

@media (min-width: 640px) {
  /* Cambia 640px por el tamaño que desees */
  .fondo2 {
    background-image: url("./img/fondo2.png"); /* Cambia la imagen para PC */
    height: 500px; /* Altura para pantallas más grandes */
  }
}

.fondo22 {
  background-image: url("./img/fondo2.png");
  height: 350px;
  width: 100%;
  background-size: cover; /* Asegúrate de que la imagen cubra el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.piedepagina {
  background-image: url("./img/pie.png");
  height: 100px;
  width: 100%;
  background-size: cover; /* Asegúrate de que la imagen cubra el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

@media (min-width: 640px) {
  /* Cambia 640px por el tamaño que desees */
  .piedepagina {
    background-image: url("./img/pie.png"); /* Cambia la imagen para PC */
    height: 300px; /* Altura para pantallas más grandes */
  }
}

.text-justify {
  text-align: justify;
}
